import FileSaver from 'file-saver';
import JSZip from 'jszip';

import _ from 'lodash';

export type zipMedia = { blob: Blob; name: string };
export type ZipData = {
  lsImages: zipMedia[];
  buildingImages: zipMedia[];
  lsDocs: zipMedia[];
  buildingDocs: zipMedia[];
  //more images outside the image folder will be ignored
  lsMainImg: zipMedia;
  buildingMainImg: zipMedia;
  excel: zipMedia[];
};

const LS_FOLDER = 'Liegenschaft';
const BUILDING_FOLDER = 'Gebaeude';
const IMAGE_FOLDER = 'Bilder';
const DOC_FOLDER = 'Dokumente';
const STR_REGEX = '(.jpg|.png|.jpeg)$';
const DOC_REGEX = '(.jpg|.png|.jpeg|.xlsx|.xls|.pdf|.doc|.docx|.ppt|.pptx)$';

//every file should be a json {name: title, blob:blob}
export const zipDocs = (
  dataDocs: { name: string; blob: Blob }[],
  zipName: string
) => {
  return new Promise((resolve, reject) => {
    let zip = new JSZip();
    if (dataDocs && dataDocs.length > 0) {
      dataDocs.forEach((doc) => {
        zip.file(doc.name, doc.blob, { base64: true });
      });
    }
    zip
      .generateAsync({ type: 'blob' })
      .then((content) => {
        FileSaver.saveAs(content, zipName);
      })
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error));
  });
};

export const unpackZip = (file: any) => {
  return new Promise((resolve, reject) => {
    let zip = new JSZip();

    zip
      .loadAsync(file, {
        base64: false,
        checkCRC32: true
      })
      .then(function (zip: any) {
        console.log(zip);
        extractData(zip)
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};

function decodeFileName(bytes: any) {
  // Decode the ArrayBuffer using the UTF-8 encoding
  const decoderUTF8 = new TextDecoder('utf-8');
  const decodedUTF8 = decoderUTF8.decode(bytes);

  // Normalize the string using Unicode normalization form C
  const normalized = decodedUTF8.normalize('NFC');

  return normalized;
}

async function extractData(zip: any) {
  const buildingImgProm: any = [];
  const lsImgProm: any = [];
  const buildingDocProm: any = [];
  const lsDocProm: any = [];
  const excelProm: any = [];
  const buildingMainImgProm: any = [];
  const lsMainImgProm: any = [];

  const imgRe = new RegExp(STR_REGEX);
  const docRe = new RegExp(DOC_REGEX);

  Object.keys(zip.files).forEach((name) => {
    console.log(name);
    const file = zip.files[name];

    if (!file.dir) {
      //if it is a file not directory
      //goes through every file, looks for files in our structure
      const path = name.substring(name.indexOf('/'), name.lastIndexOf('/')); //path if its a file
      const filename = name.substring(name.lastIndexOf('/')); //filename if its a file

      console.log(path);
      if (_.includes(path, IMAGE_FOLDER) && _.includes(path, LS_FOLDER)) {
        //images of liegenschaft
        if (imgRe.test(name.toLowerCase())) {
          //if image complies with regex
          lsImgProm.push(extractImage(file, name));
        } else {
          throw new Error(
            'Der Ordner enthält Bilder von einem nicht unterstützten Dateityp. Es werden nur Bilder des Typs JPG, JPEG und PNG akzeptiert'
          );
        }
      } else if (
        _.includes(path, IMAGE_FOLDER) &&
        _.includes(path, BUILDING_FOLDER)
      ) {
        //images of building
        if (imgRe.test(name.toLowerCase())) {
          //if image complies with regex
          buildingImgProm.push(extractImage(file, name));
        } else {
          throw new Error(
            'Der Ordner enthält Bilder von einem nicht unterstützten Dateityp. Es werden nur Bilder des Typs JPG, JPEG und PNG akzeptiert'
          );
        }
      } else if (_.includes(path, DOC_FOLDER) && _.includes(path, LS_FOLDER)) {
        //docs of liegenschaft
        if (docRe.test(name.toLowerCase())) {
          //if image complies with regex
          lsDocProm.push(extractDoc(file, name));
        } else {
          throw new Error(
            'Der Ordner enthält Dokumente mit einem nicht unterstützten Dateityp. Es werden nur Dokumente des Typs PPT, PPTX, XLSX, XLS, PDF, DOC, DOCX, JPG, JPEG und PNG akzeptiert.'
          );
        }
      } else if (
        _.includes(path, DOC_FOLDER) &&
        _.includes(path, BUILDING_FOLDER)
      ) {
        //docs of building
        if (docRe.test(name.toLowerCase())) {
          //if image complies with regex
          buildingDocProm.push(extractDoc(file, name));
        } else {
          throw new Error(
            'Der Ordner enthält Dokumente mit einem nicht unterstützten Dateityp. Es werden nur Dokumente des Typs PPT,PPTX, XLSX, XLS, PDF, DOC, DOCX, JPG, JPEG und PNG akzeptiert.'
          );
        }
      } else if (_.includes(path, LS_FOLDER)) {
        //ls hauptbild
        if (imgRe.test(name.toLowerCase())) {
          //if image complies with regex
          lsMainImgProm.push(extractImage(file, name));
        } else {
          throw new Error(
            'Der Ordner enthält Bilder von einem nicht unterstützten Dateityp. Es werden nur Bilder des Typs JPG, JPEG und PNG akzeptiert'
          );
        }
      } else if (_.includes(path, BUILDING_FOLDER)) {
        //building hauptbild
        if (imgRe.test(name.toLowerCase())) {
          //if image complies with regex
          buildingMainImgProm.push(extractImage(file, name));
        } else {
          throw new Error(
            'Der Ordner enthält Bilder von einem nicht unterstützten Dateityp. Es werden nur Bilder des Typs JPG, JPEG und PNG akzeptiert'
          );
        }
      } else if (
        _.endsWith(name, '.xlsx') &&
        !(
          _.includes(path, DOC_FOLDER) ||
          _.includes(path, IMAGE_FOLDER) ||
          _.includes(path, LS_FOLDER) ||
          _.includes(path, BUILDING_FOLDER)
        )
      ) {
        excelProm.push(extractExcel(file, name));
      }
    }
  });
  const lsImgs = await Promise.all(lsImgProm);
  const buildingImgs = await Promise.all(buildingImgProm);
  const lsDocs = await Promise.all(lsDocProm);
  const buildingDocs = await Promise.all(buildingDocProm);
  const excel = await Promise.all(excelProm);
  const lsMainImg = await Promise.all(lsMainImgProm);
  const buildingMainImg = await Promise.all(buildingMainImgProm);

  return {
    lsImages: lsImgs,
    buildingImages: buildingImgs,
    lsDocs: lsDocs,
    buildingDocs: buildingDocs,
    //more images outside the image folder will be ignored
    lsMainImg: lsMainImg[0],
    buildingMainImg: buildingMainImg[0],
    excel: excel
  };
}

function extractExcel(zipFile: any, filename: string) {
  return new Promise((resolve, reject) => {
    zipFile
      .async('blob')
      .then((data: Blob) =>
        resolve({
          name: _.last(_.split(filename, '/')),
          blob: data
        })
      )
      .catch((error: any) => reject(error));
  });
}

function extractImage(zipFile: any, filename: string) {
  return new Promise((resolve, reject) => {
    zipFile
      .async('blob')
      .then((data: Blob) =>
        resolve({
          name: _.last(_.split(filename, '/')),
          blob: data
        })
      )
      .catch((error: any) => reject(error));
  });
}

function extractDoc(zipFile: any, filename: string) {
  return new Promise((resolve, reject) => {
    zipFile
      .async('blob')
      .then((data: Blob) =>
        resolve({
          name: _.last(_.split(filename, '/')),
          blob: data
        })
      )
      .catch((error: any) => reject(error));
  });
}
