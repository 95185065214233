import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';

//automatically includes thunk middleware
export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, logger]
});

export const persistor = persistStore(store);

//export types for state and dispatch
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
